<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import Spinner from "vue-simple-spinner";
import * as moment from "moment";
import Multiselect from 'vue-multiselect';
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import FilterBtn from '../../../components/filter-btn.vue';
import DownloadBtn from '../../../components/download-btn.vue';

import {
  FETCH_PAYMENT_PAGE_LIST,
  CANCEL_PAYMENT_PAGE,
  EXPORT_PAYMENT_PAGE_LIST,
  FETCH_DETAILS_FOR_ALL_SUB_USERS
} from "@/state/actions.type";

export default {
  components: {
    Layout,
    PageHeader,
    Spinner,
    Multiselect,
    DatePicker,
    FilterBtn,
    DownloadBtn
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      createdByFilter: null,
      pageOptions: [10, 25, 50, 100],
      filters: {
        page_type: 'NORMAL'
      },
      from: "",
      to: "",
      fields: [
        { key: "show_details", sortable: false, label: 'views.payment_pages.table.fields.options' },
        { key: "page_link", sortable: false, label: "views.payment_pages.table.fields.page_url" },
        { key: "created_at", sortable: false, label: "views.payment_pages.table.fields.created_at" },
        { key: "status", sortable: false, label: "views.payment_pages.table.fields.status" },
        { key: "title", sortable: false, label: "views.payment_pages.table.fields.title" },
        { key: "merchant_email_address", sortable: false, label: "views.payment_pages.table.fields.created_by" , thClass: "",tdClass: "" },
        { key: "action", sortable: false, label: "views.payment_pages.table.fields.action" },
        // { key: "name", sortable: true, label: "Item Name" },
        // { key: "units_sold", sortable: true, label: "Units Sold" },
        // { key: "total_sales", sortable: true, label: "Total Sales" },
      ],
      statusOptions: ['Created', 'Cancelled'],
      refValueForFilter: "",
      filterApplied: false
    };
  },
  filters: {
    date(date) {
      return moment(date).format("DD/MM/YYYY HH:mm:ss");
    }
  },

  created() {
    this.fetchPaymentPageList();
    if(this.isGlobalUser && !this.isMasterMerchant) {
      this.fetchSubUsersList();
    }
  },

  watch: {
    perPage() {
      this.fetchPaymentPageList();
    },
    'from': function(newFromDate) {
      if (newFromDate !== null && newFromDate !== "2006-01-02T00:00:00.000Z" && newFromDate !== "") {
        const toDate = new Date(newFromDate);
        console.log(toDate)
        toDate.setHours(23, 59, 59); // Set time to the end of the day
        this.to = toDate;
      }
      else{
        this.to = "";
      }
    }
  },

  computed: {
    paymentPagesDataStore() {
      return this.$store.state.paymentPages.content;
    },

    isFetchingTransactions() {
      return this.$store.state.paymentPages.isFetchingData;
    },

    rows() {
      return this.$store.state.paymentPages.total_elements;
    },

    title() {
      return this.$t('views.payment_pages.title');
    },

    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    items() {
      return [
        {
          text: this.$t('views.payment_pages.payments'),
          active: true,
        },
        {
          text: this.$t('views.payment_pages.title'),
          active: true,
        }
      ];
    },

    isGlobalUser() {
      return this.$store.state.auth.currentUser.is_global_user;
    },

    subUserList() {
      return this.$store.state.accounts.subUserData || [];
    },
  },

  methods: {
    createPaymentPage() {
      this.$router.push({ path: '/settings/payment-pages/config'})
    },
    sendToDetailsPage(item) {
      this.$router.push({ path: '/settings/payment-pages-details', query: { pageRef: item.page_ref, key: this.isMasterMerchant ? item.chaipaykey : "" }})
    },
    onCopySuccess() {
      this.$notify({
        type: "success",
        text: this.$t('views.payment_pages.copy_success'),
        closeOnClick: true,
      });
    },
    fetchPaymentPageList() {
      if(this.isGlobalUser && !this.isMasterMerchant && this.createdByFilter) {
        this.filters.created_by = this.createdByFilter.uuid;
      } else {
        delete this.filters.created_by;
      }
      this.$store.dispatch(`paymentPages/${FETCH_PAYMENT_PAGE_LIST}`, {
        page: this.currentPage,
        pagesize: this.perPage,
        filters: this.filters,
        from: this.from ? moment(this.from).toJSON() : "",
        to: this.to ? moment(this.to).toJSON() : ""
      });
    },
    resetAdvanceFilters() {
      if(this.from || this.to || (this.filters.status && this.filters.status.length > 0 || this.createdByFilter)) {
        this.filters = {
          page_type: 'NORMAL'
        };
        this.from = "";
        this.to = "";
        this.createdByFilter = null;
        if(this.currentPage == 1) {
          this.fetchPaymentPageList();
        } else {
          this.currentPage = 1;
        }
      }
      this.filterApplied = false;
    },
    fetchPaymentPageOnEmptyFilter() {
      if(this.refValueForFilter == "") {
        delete this.filters.title;
        this.fetchPaymentPageList();
      }
      this.fetchPaymentPageList();
    },
    filterPaymentPageBySelectedRefFilter() {
      if(this.refValueForFilter) {
        this.filters.title = this.refValueForFilter;
      } else {
        delete this.filters.title;
      }
      this.fetchPaymentPageList();
    },
    fetchPaymentPageByFilters() {
      if(this.currentPage == 1) {
        this.fetchPaymentPageList();
      } else {
        this.currentPage = 1;
      }
      if(this.from || this.to || (this.filters.status && this.filters.status.length > 0) || this.createdByFilter) {
        this.filterApplied = true;
      } else {
        this.filterApplied = false;
      }
    },
    deletePage(pageRef) {
      this.$store.dispatch(`paymentPages/${CANCEL_PAYMENT_PAGE}`, pageRef).then(() => {
        this.fetchPaymentPageList();
      });
    },
    downloadExcel() {
      this.$store.dispatch(`paymentPages/${EXPORT_PAYMENT_PAGE_LIST}`, {
        page: this.currentPage,
        pagesize: this.perPage,
        filters: this.filters,
        from: this.from ? moment(this.from).format("YYYY-MM-DD HH:mm:ss") : "",
        to: this.to ? moment(this.to).format("YYYY-MM-DD HH:mm:ss") : ""
      });
    },
    notAfterToday(date) {
      if(this.to) {
        return date > new Date() || date >= new Date(this.to);
      }
      return date > new Date();
    },
    notAfterTodayAndBeforeStartDate(date) {
      if(this.from) {
        return date > new Date() || date <= new Date(this.from);
      }
      return date > new Date();
    },
    fetchSubUsersList() {
      this.$store.dispatch(`accounts/${FETCH_DETAILS_FOR_ALL_SUB_USERS}`);
    }
  },

  beforeDestroy() {
    this.$store.dispatch('notification/clear');
  },
};
</script>

<template>
  <Layout>
    <PageHeader :items="items" :title="title" />
    <div class="row">
      <div class="col-lg-12">
        <div class="row my-4">
          <div class="col-sm-12 col-md-12 d-flex justify-content-end">
            <b-btn
              variant="primary"
              size="sm"
              v-if="!isMasterMerchant"
              @click="createPaymentPage"
            >
              {{ $t('views.payment_pages.create') }}
            </b-btn>
          </div>
        </div>

        <div class="row my-4">
          <div class="col-sm-12 col-md-6 d-flex align-items-baseline">
            <div @click="resetAdvanceFilters">
              <FilterBtn :isActive="filterApplied" v-b-toggle.payment-page-filter-collapse />
            </div>
            <div class="dataTables_length ml-3">
              <label class="d-flex align-items-baseline">
                <span class="text-nowrap">{{ $t('common.show') }}</span>&nbsp;
                <b-form-select v-model="perPage" size="sm" :options="pageOptions" @input="fetchPaymentPageList"></b-form-select>&nbsp;
                <span class="text-nowrap">{{ $t('common.entries') }}</span>
              </label>
            </div>
          </div>

          <div class="col-sm-12 col-md-6 d-flex justify-content-end align-items-baseline">
            <b-input-group class="w-50">
              <b-form-input type="search" v-model="refValueForFilter" @keyup="fetchPaymentPageOnEmptyFilter" @keyup.enter="filterPaymentPageBySelectedRefFilter" class="form-control form-control-sm ml-2" :placeholder="$t('common.search_placeholder')"></b-form-input>
              <b-input-group-append>
              <button class="btn btn-primary btn-block btn-sm" style="border-radius: 0px;" @click="filterPaymentPageBySelectedRefFilter">{{ $t('button.search') }}</button>
              </b-input-group-append>
            </b-input-group>
            <div class="down-btn" @click="downloadExcel">
              <DownloadBtn />
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-sm-12 col-md-12">
            <b-collapse id="payment-page-filter-collapse">
              <b-card class="border border-light" :title="$t('common.filters')">
                <div class="row mt-3">
                  <div class="col-sm-12 col-md-4 col-xl-3">
                    <label for="startdate" style="display: block;">{{ $t('common.start_date') }}</label>
                    <date-picker
                      type="date"
                      :placeholder="$t('common.start_date')"
                      format="DD-MM-YYYY HH:mm"
                      v-model="from"
                      name="startdate"
                      class="custom-datepicker"
                      prefix-class="xmx"
                      :disabled-date="notAfterToday"
                    ></date-picker>
                  </div>
                  <div class="col-sm-12 col-md-4 col-xl-3">
                    <label for="enddate" style="display: block;">{{ $t('common.end_date') }}</label>
                    <date-picker
                      type="datetime"
                      :placeholder="$t('common.end_date')"
                      v-model="to"
                      format="DD-MM-YYYY HH:mm"
                      name="enddate"
                      class="custom-datepicker"
                      prefix-class="xmx"
                      :disabled-date="notAfterTodayAndBeforeStartDate"
                    ></date-picker>
                  </div>
                  <b-form-group
                    label="Created By"
                    label-for="created_by"
                    class="col-sm-12 col-md-4 col-xl-3 my-0"
                    v-if="isGlobalUser && !isMasterMerchant"
                  >
                    <multiselect
                      v-model="createdByFilter"
                      :options="subUserList"
                      placeholder="Search User"
                      size="sm"
                      track-by="uuid"
                      label="email_address"
                      :searchable="true"
                      :taggable="true"
                      class="sm-multiselect"
                      name="created_by"
                    ></multiselect>
                  </b-form-group>
                </div>
                <div class="row mt-3">
                  <b-form-group
                    label-for="status"
                    class="col-sm-12 col-md-4 col-xl-3 my-0"
                  >
                    <multiselect
                      v-model="filters.status"
                      :options="statusOptions"
                      :placeholder="$t('common.search_status')"
                      size="sm"
                      :searchable="true"
                      :multiple="true"
                      :taggable="true"
                      class="sm-multiselect"
                      name="status"
                    ></multiselect>
                  </b-form-group>
                  <div class="col-sm-12 col-md-6 col-xl-3 ml-auto">
                    <div class="row">
                      <div class="col-sm-12 col-md-6">
                        <b-button variant="primary" style="height: 35px;" class="btn btn-block btn-sm" @click="fetchPaymentPageByFilters" >{{ $t('button.apply') }}</b-button>
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <b-button variant="light" style="height: 35px;" class="btn btn-block btn-sm" @click="resetAdvanceFilters" v-b-toggle.payment-page-filter-collapse>{{ $t('button.clear') }}</b-button>
                      </div>
                    </div>
                  </div>
                </div>
              </b-card>
            </b-collapse>
          </div>
        </div>

          <b-table
            class="table-custom text-center table-centered"
            :items="paymentPagesDataStore"
            :fields="fields"
            responsive="sm"
            :per-page="perPage"
            :bordered="false"
            :small="true"
            sticky-header="500px"
            show-empty
            :empty-text="$t('common.no-records')"
            busy.sync="true"
            hover
          >
            <template v-slot:head()="data">
              {{ $t(data.label) }}
            </template>
            <template #cell(show_details)="row">
              <b-dropdown size="sm" class="ml-1" variant="light">
                <template v-slot:button-content>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-three-dots-vertical" viewBox="0 0 16 16">
                    <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z"/>
                  </svg>
                </template>
                <b-dropdown-item-button @click="sendToDetailsPage(row.item)">{{ $t("views.payment_pages.page_details") }}</b-dropdown-item-button>
              </b-dropdown>
            </template>
            <template v-slot:cell(page_link)="{ value, item }">
              <span v-show="!value">{{ $t('common.na') }}</span>
              <div v-show="value" class="d-flex justify-content-center align-items-center">
                <a class="d-flex" target="_black" v-b-tooltip.hover.right :title="item.page_ref" :href="value">
                  <p class="text-truncate mb-0" style="width: 6rem;">{{ item.page_ref }}</p>
                  <i style="font-size: 15px;" class="mdi mdi-open-in-new"></i>
                </a>
                <span class="ml-4">
                  <i
                    v-show="value"
                    v-clipboard:copy="value"
                    v-clipboard:success="onCopySuccess"
                    v-b-tooltip.hover.right
                    :title="$t('common.copy')"
                    class="mdi mdi-content-copy btn btn-lg p-0"
                  ></i>
                </span>
              </div>
            </template>
            <template v-slot:cell(created_at)="{ value }">
              <div>
                {{ value | date }}
              </div>
            </template>
            <template v-slot:cell(status)="{ value }">
              <div
                class="badge font-size-12"
                :class="{
                  'badge-soft-success': `${value}` === 'Created',
                  'badge-soft-danger': `${value}` === 'Cancelled',
                }"
              >
              <!-- 'badge-soft-warning': `${value}` === 'Created', -->
                {{ value }}
              </div>
            </template>
            <template v-slot:cell(action)="{ item }">
              <b-btn size="sm" variant="danger" v-if="item.status !== 'Cancelled'" @click="deletePage(item.page_ref)">
                {{ $t('button.cancel') }}
              </b-btn>
            </template>
          </b-table>
          <Spinner v-if="isFetchingTransactions === true"></Spinner>
        <div class="row">
          <div class="col">
            <div
              class="dataTables_paginate paging_simple_numbers float-right"
              style="padding-bottom: 20px;"
            >
              <ul class="pagination pagination-rounded mb-0">
                <!-- pagination -->
                <b-pagination
                  v-model="currentPage"
                  :current-page="currentPage"
                  :total-rows="rows"
                  :per-page="perPage"
                  @input="fetchPaymentPageList"
                ></b-pagination>
              </ul>
            </div>
            <div class="clear-both"></div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<style>
.xmx-table-date .today {
  color: #2a90e9;
}
</style>
<style lang="css" scoped>
::v-deep .table > tbody > tr > td {
  white-space: nowrap;
  background: #fff;
  border: none;
  border-bottom: 1px solid #eff2f7;
  padding-top: 12px;
  padding-bottom: 12px;    
  font-size: 13px;
}

::v-deep .table > thead > tr > th {
  white-space: nowrap;
  font-size: medium;
}

::v-deep .modal-dialog > {
  min-width: 80%;
  min-height: 80%;
}

::v-deep .modal-dial > .trxn-status {
  color: red;
}
::v-deep .xmx-datepicker{
  width: 100%;
}
::v-deep .custom-datepicker .xmx-input{
  height: 44px !important;
  padding-top: 4px;
  min-height: unset;
  border: 1px solid #DCDAD5;
  box-shadow: none;
  border: 1px solid #DCDAD5;
  border-radius: 12px;
}
.file_Upload{
    opacity: 0;
    height: 30px;
    position: absolute;
    top: 0;
    left: 0px;
    width: 140px;
    z-index: 3000;
    cursor: pointer;
}
.bulk-upload-button{
    width: 120px!important;
    text-align: center;
    color: #B3AFAF;
    background-color: #252B3A;
    cursor: pointer;
    border-radius: 5px;
    display: block;
    padding: 10px;
}
.media-body-custom{
  font-size: 12px;
  position: relative;
}
.media-body-custom img{
  width: 15px;
  height: 15px;
  position: relative;
  margin-right: 10px;
  bottom:2px;
}
.btn-custom{
  border: 1px solid #252B3B;
  border-radius: 4px;
  background: #FFFCFC;
  color: #252B3B;
}
.btn-custom.disabled{
  pointer-events: none;
}
.search-button{
  background: #EAEAEA;
  border-color: #EAEAEA;
  color: #B3AFAF;
}
::v-deep .main-content {
    overflow: visible;
}
::v-deep  .datepicker-custom .reportrange-text{
  border: 1px solid #EAEAEA;
  border-radius: 6px;
  font-size: 13px;
  color: #000;
  height: auto;
  padding: 5px 10px;
} 
.bulk-upload-button:hover{
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 10px;
    background-color: #191d26;
}
.file_Upload:hover + .bulk-upload-button{
    box-shadow: rgb(0 0 0 / 15%) 0px 5px 10px;
    background-color: #191d26;
}
input#file-upload-button{
  cursor: pointer;
}
.btn-link {
  color: #5664d2;
}
.btn-link:hover {
  color: #07080b;
  text-decoration: none;
}
.down-btn button{
  height: 31px;
  position: relative;
  top: 4px;
  margin-left: 10px;
}
::v-deep .btn{
  border-radius: 4px;
}
</style>